(function () {
	'use strict';
	angular.module('eventvods')
		.controller('PlayerController', ['API_BASE_URL', '$rootScope', '$http', '$routeParams', '$timeout', '$window', '$interval', '$location',
			function (API, $rootScope, $http, $routeParams, $timeout, $window, $interval, $location) {
				var vm = this,
					player = null,
					$player = $('#player'),
					$frame,	startAt, playAll;

				vm.setup = {
					loading: true,
					requestFullScreen: false,
					volume: 100,
					qualities: [],
					quality: "default",
					speeds: [],
					speed: 1
				};

				vm.gameIndex = parseInt($routeParams.game || 1) - 1;
				// checks if autoplay param is defined, otherwise false.
				// converts to truthy value using ~str.indexOf and finally bool using !!
				playAll = !!~($routeParams.autoplay || "false").indexOf('true');
				startAt = $routeParams.start || "picksBans";

				switch(startAt.toLowerCase()){
					case "gamestart":
					case "game":
						startAt = "gameStart";
						break;
					case "highlights":
						startAt = "highlights";
						break;
					default:
						startAt = "picksBans";
						break;
				}

				$window.onYouTubeIframeAPIReady = function () {
					$timeout(function () {
						vm.setup.loading = false;
						angular.element(document).duScrollTo(0, (($(window).height() - $player.height()) / 4), 1000);
					}, 0);
					player = new YT.Player('player-video', {
						height: vm.setup.height,
						width: vm.setup.width,
						videoId: vm.setup.video,
						playerVars: {
							autoplay: false,
							start: vm.setup.time,
							controls: 0,
							rel: 1,
							iv_load_policy: 3,
							modestbranding: 1,
							playsInline: 1
						},
						events: {
							'onReady': function () {
								$frame = document.querySelector('#player-video');
								vm.setup.requestFullScreen = $frame.requestFullScreen || $frame.mozRequestFullScreen || $frame.webkitRequestFullScreen;
								vm.setup.volume = player.getVolume();
								vm.setup.mute = player.isMuted();
								var check = $interval(function(){
									vm.setup.quality = player.getPlaybackQuality();
									vm.setup.qualities = player.getAvailableQualityLevels();
									vm.setup.speed = player.getPlaybackRate();
									vm.setup.speeds = player.getAvailablePlaybackRates();
									if(vm.setup.qualities.length > 0 && vm.setup.speeds.length > 0) $interval.cancel(check);
								}, 1000);
							},
							'onStateChange': function (event) {
								$timeout(function () {
									vm.setup.status = event.data;
									if(event.data === 0 && playAll) vm.nextGame();
								}, 0);
							},
							'onError': function(event){
								$timeout(function () {
									displayError(event.data);
								}, 0);
							},
							'onPlaybackQualityChange': function(event){
								$timeout(function () {
									vm.setup.quality = player.getPlaybackQuality();
									if(vm.setup.qualities.length == 0) vm.setup.qualities = player.getAvailableQualityLevels();
								}, 0);
							},
							'onPlaybackRateChange': function(event){
								$timeout(function () {
									vm.setup.speed = player.getPlaybackRate();
									if(vm.setup.speeds.length == 0) vm.setup.speeds = player.getAvailablePlaybackRates();
								}, 0);
							}
						}
					});
				}

				vm.prettify = function(inputStr){
					switch(inputStr){
						case "small":
							return "240P";
						case "medium":
							return "360P";
						case "large":
							return "480P";
						case "hd720":
							return "720P";
						case "hd1080":
							return "1080P";
						case "highres":
							return "4K";
						case "unknown":
							return "";
						default:
							return inputStr;
					}
				}
				vm.play = function () {
					player.playVideo();
				}
				vm.pause = function () {
					player.pauseVideo();
				}
				vm.changeVolume = function(){
					vm.setup.mute = false;
					player.setVolume(vm.setup.volume);
				}
				vm.fullscreen = function(){
					if (vm.setup.requestFullScreen) {
					  vm.setup.requestFullScreen.bind($frame)();
					}
				}
				vm.setQuality = function(index){
					var current = vm.setup.qualities.indexOf(vm.setup.quality),
						playAllTemp = playAll,
						playAll = false;
					if(current == index) return;
					var t = player.getCurrentTime();
					player.stopVideo();
					player.setPlaybackQuality(vm.setup.qualities[index]);
					player.seekTo(t);
					player.playVideo();
					playAll = playAllTemp;
				}
				vm.setSpeed = function(index){
					var speed = vm.setup.speeds[index];
					player.setPlaybackRate(speed);
				}
				vm.draft = function(){
					var url = vm.data.data[vm.gameIndex].youtube.picksBans;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseYoutube(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seekTo(vm.setup.time);
						})
						return;
					}
					var start = parseYoutube(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seekTo(vm.setup.time);
				}
				vm.gameStart = function(){
					var url = vm.data.data[vm.gameIndex].youtube.gameStart;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseYoutube(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seekTo(vm.setup.time);
						})
						return;
					}
					var start = parseYoutube(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seekTo(vm.setup.time);
				}
				vm.highlights = function(){
					var url = vm.data.data[vm.gameIndex].youtube.highlights;
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseYoutube(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seekTo(vm.setup.time);
						})
						return;
					}
					var start = parseYoutube(url);
					if (!start) return displayError("Invalid video URL.", true);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seekTo(vm.setup.time);
				}
				vm.gotoGame = function(index){
					vm.gameIndex = index;
					$location.search('game', (vm.gameIndex+1));
					if (vm.data.data[vm.gameIndex].placeholder) return displayError("Match complete - this is is just included as a placeholder to prevent spoilers.", true);
					var url = vm.data.data[vm.gameIndex].youtube[startAt];
					if(url.indexOf('ev.wtf') > -1){
						$http.get(url.replace('http:','https:')+'?json=true')
						.then(function(response){
							var start = parseYoutube(response.data.link);
							if (!start) return displayError("Invalid video URL.", true);
							$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
							vm.setup.time = start.time;
							if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
							else player.seekTo(vm.setup.time).playVideo();

						})
						return;
					}
					var start = parseYoutube(url);
					if (!start) return displayError("Invalid video URL.", true);
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					vm.setup.time = start.time;
					if(vm.setup.video != start.vid) switchVideo(start.vid, start.time);
					else player.seekTo(vm.setup.time).playVideo();
				}
				vm.nextGame = function(){
					vm.gotoGame(vm.gameIndex + 1);
				}
				$rootScope.$on('$routeUpdate', function(){
					var newIndex = parseInt($routeParams.game || 1) - 1;
					if(newIndex == vm.gameIndex) return;
					delete vm.setup.error;
					vm.gotoGame(newIndex);
				})
				vm.skip = function(seconds){
					var time = player.getCurrentTime() || 0;
					time += seconds;
					if(time < 0) time = 0;
					player.seekTo(time);
				}
				vm.mute = function(){
					vm.setup.mute = true;
					player.mute();
				}
				vm.unMute = function(){
					vm.setup.mute = false;
					player.unMute();
				}
				function displayError(message, stopVideo){
					vm.setup.loading = false;
					vm.setup.error = message;
					if(stopVideo) player.pauseVideo();
					console.error(message);
				}
				function playerSize() {
					$timeout(function () {
						vm.setup.width = $player.width();
						vm.setup.height = vm.setup.width * 9 / 16;
						$('iframe#player-video').attr('width', vm.setup.width).attr('height', vm.setup.height);
					}, 0);
				};

				function timeToSeconds(time) {
					if (typeof time === "undefined") return 0;
					time = /((\d+)h)?((\d+)m)?((\d+)s)?/i.exec(time);
					for (var i = 0; i < time.length; i++) {
						if (typeof time[i] === "undefined") time[i] = 0;
					}
					return (parseInt(time[2] * 3600) + parseInt(time[4] * 60) + parseInt(time[6]));
				}
				function parseYoutube(link) {
					var yt = /http(s|):\/\/(www\.|)(youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)([&\?]t=(\S+)?)?/i.exec(link);
					if (yt == null) return false;
					else return {
						vid: yt[4],
						time: timeToSeconds(yt[6])
					}
				}
				function initPlayer() {
					$rootScope.$emit('setWatched', vm.data._id, vm.gameIndex);
					if($('#ytAPIScript').length > 0) return $window.onYouTubeIframeAPIReady();
					var tag = document.createElement('script');
					tag.src = "https://www.youtube.com/iframe_api";
					tag.id = "ytAPIScript";
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}
				function switchVideo(video, time){
					vm.setup.video = video;
					vm.setup.time = time;
					player.pauseVideo();
					player.loadVideoById(vm.setup.video, vm.setup.time, vm.setup.quality);
				}

				playerSize();

				$(window).on("resize orientationchange ", playerSize);
				$http.get(API + '/match/' + $routeParams.match)
					.then(function (res) {
						vm.data = res.data;
						$rootScope.oldGame = $rootScope.game || null;
						$rootScope.game = $routeParams.gameslug;
						document.title = $rootScope.meta.title = vm.data.team1.name + ' vs ' + vm.data.team2.name + ' - ' + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ' - Eventvods';
						$rootScope.meta.description = "Watch " + vm.data.team1.name + " vs " + vm.data.team2.name + " during " + vm.data.event.shortTitle + (vm.data.event.subtitle ? ', ' + vm.data.event.subtitle : '') + ". Watch all esports matches easily and spoiler-free on Eventvods.";
						if(vm.data.highlightsIndex >= 0) vm.data.data = vm.data.data.map(function(game){
							game.youtube = (game.youtube || {});
							game.youtube.highlights = game.links[vm.data.highlightsIndex];
							return game;
						})
						var url = vm.data.data[vm.gameIndex].youtube[startAt];
						//Temporary setup
						if(url.indexOf('ev.wtf') > -1){
							$http.get(url.replace('http:','https:')+'?json=true')
							.then(function(response){
								var start = parseYoutube(response.data.link);
								if (!start) return displayError("Unable to parse that video URL.");
								vm.setup.video = start.vid;
								vm.setup.time = start.time;
								initPlayer();
							})
							return;
						}
						var start = parseYoutube(url);
						if (!start) return displayError("Unable to parse that video URL.");
						vm.setup.video = start.vid;
						vm.setup.time = start.time;
						initPlayer();
					}, function (err) {
						displayError(err.message);
						$rootScope.meta.status = 404;
					});

			}
		]);
}());
